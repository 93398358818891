<template>
  <div>
    <div class="col-xxl-12 px-0">
      <div
        class="card card-custom card-stretch gutter-b"
        :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
      >
        <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column col-xxl-5 px-0">
            <div class="form-group mb-0 col-xxl-10 px-0">
              <input
                id="client_table_search"
                v-model="search"
                type="text"
                :placeholder="$t('GENERAL.INPUT.SEARCH')"
                class="form-control form-control-solid h-auto py-3 px-5"
                :disabled="loadingComplete == false"
              />
            </div>
          </h3>
          <div class="card-toolbar">
            <ul class="nav nav-pills nav-pills-sm nav-dark-75">
              <li class="nav-item">
                <router-link
                  to="/clients/add"
                  v-if="canAddClient"
                  tag="a"
                  class="btn btn-primary py-2 px-4"
                >
                  {{ $t('GENERAL.BUTTONS.ADD_CLIENT') }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body py-0">
          <div class="table-responsive">
            <table
              id="client_table"
              class="table table-head-custom table-vertical center table-hover"
              style="table-layout:auto"
            >
              <thead>
                <tr>
                  <th class="px-2 py-3" rowspan="2" style="width:30px"></th>
                  <th class="px-2 py-3" rowspan="2" style="min-width:250px">
                    {{ $t('TABLES.CLIENT_HEADERS.NAME') }}
                  </th>
                  <th class="px-2 py-3 text-left" rowspan="2" style="min-width:175px">
                    {{ $t('TABLES.CLIENT_HEADERS.ACCOUNT_MANAGER') }}
                  </th>
                  <th class="px-2 py-3 text-left" rowspan="2" style="min-width:100px">
                    {{ $t('TABLES.CLIENT_HEADERS.COUNTRY') }}
                  </th>
                  <th colspan="5" class="border-bottom-0 py-2 px-4 notes text-center">
                    {{ $t('TABLES.CLIENT_HEADERS.PROJECTS') }}
                  </th>
                  <th class="px-5 py-3 text-center" rowspan="2" style="width:130px">
                    {{ $t('TABLES.CLIENT_HEADERS.ACTIONS') }}
                  </th>
                </tr>
                <tr>
                  <th class="px-2 pb-3 pt-2 sub notes text-center" style="width:100px; max-width:120px">
                    {{ $t('TABLES.CLIENT_HEADERS.DRAFT_PROJECTS') }}
                  </th>
                  <th class="px-2 pb-3 pt-2 sub notes text-center" style="width:120px; max-width:140px">
                    {{ $t('TABLES.CLIENT_HEADERS.RECRUITMENT_PROJECTS') }}
                  </th>
                  <th class="px-2 pb-3 pt-2 sub notes text-center" style="width:100px; max-width:120px">
                    {{ $t('TABLES.CLIENT_HEADERS.ACTIVE_PROJECTS') }}
                  </th>					
                  <th class="px-2 pb-3 pt-2 sub notes text-center" style="width:100px; max-width:120px">
                    {{ $t('TABLES.CLIENT_HEADERS.REVIEW_PROJECTS') }}
                  </th>
                  <th class="px-2 pb-3 pt-2 sub notes text-center" style="width:100px; max-width:120px">
                    {{ $t('TABLES.CLIENT_HEADERS.CLOSED_PROJECTS') }}
                  </th>	
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, i) in clientList">
                  <tr
                    v-bind:key="i"
                    v-if="
                      clientInSearch(item) &&
                        clientList.length > 0 &&
                        loadingComplete
                    "
                    v-on:mouseover="hoveringRow = i"
                    v-on:mouseleave="hoveringRow = null"
                  >
                    <td class="px-2 py-4 d-table-cell">
                      <span
                        class="label label-dot mr-2"
                        :class="item.active ? 'label-primary' : 'label-danger'"
                        v-b-tooltip.hover
                        :title="item.active ? 'Published' : 'Unpublished'"
                      >
                      </span>
                    </td>
                    <td class="px-2 py-3 d-table-cell">
                      <a
                        href="javascript:void(0)"
                        class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
                        @click="viewClient(item.key)"
                      >
                        {{ item.name }}
                      </a>
                      <span
                        class="text-muted font-weight-bold text-muted d-block"
                        >{{ item.key }}</span
                      >
                    </td>
                    <td class="px-2 py-5 d-table-cell font-size-sm">
                      {{ accountManager(item.account_manager) }}
                    </td>
                    <td class="px-2 py-5 d-table-cell">
                      <span class="text-dark-65 font-weight-bold d-block font-size-sm">
                        {{ item.country.name }}
                      </span>
                    </td>
                    <td class="px-2 py-5 text-center d-table-cell">
                      <span class="text-dark-65 font-weight-bold d-block font-size-sm">
                        {{ item.Draft }}
                      </span>
                    </td>
                    <td class="px-2 py-5 text-center d-table-cell">
                      <span class="text-dark-65 font-weight-bold d-block font-size-sm">
                        {{ item.Recruitment }}
                      </span>
                    </td>
                    <td class="px-2 py-5 text-center d-table-cell">
                      <span class="text-dark-65 font-weight-bold d-block font-size-sm">
                        {{ item.Active }}
                      </span>
                    </td>
                    <td class="px-2 py-5 text-center d-table-cell">
                      <span class="text-dark-65 font-weight-bold d-block font-size-sm">
                        {{ item.Review }}
                      </span>
                    </td>
                    <td class="px-2 py-5 text-center d-table-cell">
                      <span class="text-dark-65 font-weight-bold d-block font-size-sm">
                        {{ item.Closed }}
                      </span>
                    </td>
                    <td class="pr-0 pt-4 text-right d-table-cell">
                      <a
                        v-if="hoveringRow == i"
                        href="javascript:void(0)"
                        class="btn btn-icon btn-light btn-hover-success btn-sm"
                        v-b-tooltip.hover.bottom
                        :title="$t('TABLES.CLIENT_TOOLTIPS.VIEW_PROJECTS')"
                        @click="viewClient(item.key)"
                      >
                        <span class="svg-icon svg-icon-md svg-icon-success">
                          <inline-svg
                            src="media/svg/icons/Files/Folder.svg"
                          />
                        </span>
                      </a>
                      <a
                        v-if="hoveringRow == i"
                        href="javascript:void(0)"
                        class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        v-b-tooltip.hover.bottom
                        :title="$t('TABLES.CLIENT_TOOLTIPS.EDIT_CLIENT')"
                        @click="editClient(item.key)"
                      >
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <inline-svg src="media/svg/icons/Design/Edit.svg" />
                        </span>
                      </a>
                    </td>
                  </tr>
                </template>
                <tr v-if="clientList.length == 0 && loadingComplete">
                  <td class="px-2 text-center" colspan="11">
                    <span class="text-dark-65 font-weight-bold d-block py-3">
                      {{ $t('TABLES.CLIENT_MESSAGES.NO_CLIENTS_TEXT') }}
                      <router-link tag="a" :to="{ name: 'add_client' }">
                        {{ $t('TABLES.CLIENT_MESSAGES.NO_CLIENTS_LINK') }}
                      </router-link>
                    </span>
                  </td>
                </tr>
                <tr v-if="!loadingComplete && !error.active">
                  <td class="px-2 text-center border-0" colspan="10">
                    <b-spinner
                      variant="primary"
                      label="Spinning"
                      class="table-loader my-3"
                    >
                    </b-spinner>
                  </td>
                </tr>
                <tr v-if="error.active">
                  <td class="px-2 text-center border-0" colspan="10">
                    <b-alert show variant="light">
                      {{ error.message }}
                    </b-alert>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ClientTable",

  props: {
      clientList: { type: Array, required: true },
      loadingComplete: { type: Boolean, required: true },
      error: { type: Object, required: true }
  },

  data() {
    return {
      search: "",
      hoveringRow: null
    };
  },

  methods: {
    clientInSearch(client) {
      let name = client.name.toLowerCase();
      let key = client.key.toLowerCase();
      let system_country_name = client.country.name.toLowerCase();
      let account_manager = this.accountManager(client.account_manager).toLowerCase();

      if (
        name.includes(this.search.toLowerCase()) ||
        key.includes(this.search.toLowerCase()) ||
        system_country_name.includes(this.search.toLocaleLowerCase()) ||
        account_manager.includes(this.search.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    },
    viewClient(clientKey) {
      let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
      window.location.href = protocol + clientKey + '.' + process.env.VUE_APP_ADMIN_URL_SUFFIX + "/#/client/projects";
    },
    editClient(clientKey) {
      let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
      window.location.href = protocol + clientKey + '.' + process.env.VUE_APP_ADMIN_URL_SUFFIX + "/#/client/edit";
    },
    accountManager(accountManager) {
      if(accountManager == null) {
        return `${this.$t("TABLES.CLIENT_PLACEHOLDERS.NO_AM")}`;
      } else {
        return accountManager.display_name;
      }
    }
  },

  computed: {
    ...mapGetters(["currentUser"]),

    canAddClient: function() {
      if (this.currentUser.system_admin_role_id == 1) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
