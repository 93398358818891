<template>
  <div>
    <ClientTable
      :clientList="clientList"
      :loadingComplete="loadingComplete"
      :error="error"
    >
    </ClientTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import { POPULATE_SCOPED_CLIENT_LIST } from "@/core/services/store/core/client.module";

import PageLoader from "@/core/classes/PageLoader.js";

import ClientTable from "@/view/content/clients/ClientTable";

export default {
  name: "ViewClients",

  components: {
    ClientTable
  },

  data() {
    return {
      pageLoader: new PageLoader(1),
      error: {
        active: false,
        message: ""
      }
    };
  },

  mounted() {
    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },]);
    this.fetchClients();
  },

  methods: {
    fetchClients() {
      let payload = {
        userId: this.currentUser.id,
        params: {
          paginate: false,
          projects: "withCount",
          country: "with",
          accountManager: "with"
        }
      };
      this.$store
        .dispatch(POPULATE_SCOPED_CLIENT_LIST, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent(1);
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the client list, please check back later or contact the helpdesk";
        });
    },
    statHandler(stat) {
      if(this.pageLoader.componentsCompleted < this.pageLoader.componentsRequired) {
        return null;
      } else {
        return stat;
      }
    }
  },

  computed: {
    ...mapGetters(["clientList", "currentUser"]),

    loadingComplete() {
      return this.pageLoader.getStatus();
    },
  }
};
</script>
